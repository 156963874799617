import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'
import { log } from '../utils/log.js'
import { ContextProvider, createContext } from '@lit/context'

export interface IEmpty {
  empty: boolean
}

export interface IEmptyContext {
  setEmpty(empty: boolean): void
  init(): void
}

const contextIdentifier = Symbol('emptyContext')
export const context = createContext<IEmptyContext>(contextIdentifier)

export const Empty = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class EmptyClass extends SuperClass {
    #allowMutation = true
    #context = new ContextProvider(this, {
      context,
      initialValue: {
        setEmpty: async (empty: boolean) => {
          this.#allowMutation = true
          this.empty = empty
          await this.updateComplete
          this.#allowMutation = false
        },
        init: () => {
          this.#allowMutation = false
        }
      }
    })

    /**
     * Indicates if the element is considered empty or not. Should only be set for custom implementations.
     * When OneUx elements are used as children this is set automatically and will error out if an attempt is made to set it.
     */
    @property({ type: Boolean })
    public set empty(value: boolean) {
      if (!this.#allowMutation) {
        log.error({
          title: `Can't mutate value "empty".`,
          message: `The value "empty" is controlled though the child component and should not be set manually when used with OneUx children.`,
          details: this
        })
        return
      }
      this.#empty = value
    }
    public get empty() {
      return this.#empty
    }
    #empty = false
  }

  return EmptyClass as Constructor<IEmpty> & TSuperClass
}

import { type PropertyValues, html, nothing } from 'lit'
import { consume } from '@lit/context'
import { customElement, property, state } from 'lit/decorators.js'
import { type Ref, createRef, ref } from 'lit/directives/ref.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import type { color, optionData, groupData, optionInput, colorMatrix } from './types.js'
import { IValue, ValueFactory } from '../../mixins/Value.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import type { Optional } from '../../types.js'
import { log } from '../../utils/log.js'
import { defaultDropdownContext, dropdownContext } from '../../contexts/DropdownContext.js'
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { ColorChip } from './fragments/ColorChip.js'
import { ColorOption } from './fragments/ColorOption.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { keyCodes, scrollElementIntoView } from '../../utils.js'
import { ActiveOptionCalculator } from './ActiveOptionCalculator.js'
import { OneUxScrollElement } from '../one-ux-scroll/OneUxScrollElement.js'
import { IRequired, Required } from '../../mixins/Required.js'
import { ValidatedFactory, validResult, getFormValidationLanguage } from '../../mixins/Validated.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { toNormalizedGroups } from './toNormalizedGroups.js'
import { getLangCode, type langCode } from '../../utils/getLangCode.js'
import { lang } from './language.js'
import { ID_ACTIVE_OPTION, NUMBER_OF_COLUMNS_IN_SWATCH } from './constants.js'
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js'

const Styled = StyledFactory(style)
type valueType = string
const Value = ValueFactory<valueType>({})
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldIsRequired } = getFormValidationLanguage(this)
    const valid = !this.empty
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    }
  }
})

const BaseClass = FormAssociated(Required(Validated(Disabled(Focusable(Value(Styled(OneUxElement)))))))

type RootRefs = {
  $colorPicker: Ref<HTMLElement>
  $scroll: Ref<OneUxScrollElement>
}

@customElement('one-ux-color-picker')
export class OneUxColorPickerElement extends BaseClass {
  @property({ type: Array })
  public accessor options = [] as optionInput[]

  /** @internal */
  @state()
  accessor _internalGroups = [] as groupData[]

  /** @internal */
  @state()
  accessor _activeOption: Optional<optionData>

  /** @internal */
  @consume({ context: dropdownContext, subscribe: true })
  _dropdownContext = defaultDropdownContext

  /** @internal */
  @consume({ context: popoutContext, subscribe: true })
  _popoutContext = defaultPopoutContext

  /** @internal */
  @consume({ context: labelContext, subscribe: true })
  _labelContext = defaultLabelContext

  constructor() {
    super()

    this.addEventListener('focus', () => {
      this._activeOption = this._selectedOption ?? this._internalGroups[0]?.options[0]
    })
  }

  protected willUpdate(changed: PropertyValues<this>): void {
    const [langCode, isLanguageUpdated] = this.#getLangCode()
    if (changed.has('options') || isLanguageUpdated) {
      this._internalGroups = toNormalizedGroups(this.options, lang[langCode])

      if (!this._internalGroups.length) {
        log.warning('No valid colors')
      }

      const duplicatedColors = this.#getDuplicateColors()
      if (duplicatedColors.length) {
        log.error(`Duplicated colors found: ${duplicatedColors.join(', ')}`)
        this._internalGroups = []
      }
    }

    if (changed.has('value') || !this.hasUpdated) {
      const option = this._selectedOption
      const preview = option ? ColorChip.call(this, option) : nothing
      this._dropdownContext.updatePreview(preview)
    }
  }

  protected updated(changed: PropertyValues<this>) {
    if (changed.has('_activeOption') && this.shadowRoot?.activeElement) {
      scrollElementIntoView(this.#refs.$scroll.value, this.shadowRoot!.querySelector(`#${ID_ACTIVE_OPTION}`)!)
    }
  }

  protected render() {
    if (!this._internalGroups.length) {
      return nothing
    }

    const Swatch = (colorMatrix: colorMatrix) =>
      html`${colorMatrix.map(
        (row) =>
          html`<tr>
            ${row.map((option) => ColorOption.call(this, option))}
          </tr>`
      )}`

    return html`<one-ux-scroll ${ref(this.#refs.$scroll)} class="one-ux-element--root">
      <table
        ${ref(this.#refs.$colorPicker)}
        role="grid"
        tabindex=${ifDefined(this.disabled ? undefined : '0')}
        aria-activedescendant=${ifDefined(this._activeOption ? ID_ACTIVE_OPTION : undefined)}
        aria-disabled=${this.disabled}
        aria-label=${ifDefined(this._labelContext.label || undefined)}
        @keydown=${this.#handleKeyDown}
      >
        <colgroup span=${NUMBER_OF_COLUMNS_IN_SWATCH}>
          ${Array.from({ length: NUMBER_OF_COLUMNS_IN_SWATCH }).map(() => html`<col />`)}
        </colgroup>

        ${this._internalGroups.map(
          (group) =>
            html`<tbody>
              ${group.heading
                ? html`<tr>
                    <th colspan=${NUMBER_OF_COLUMNS_IN_SWATCH} scope="rowgroup">
                      <div ${ref(($ref?: Element) => this.#handleTooltip(group.heading, $ref))}>${group.heading}</div>
                    </th>
                  </tr>`
                : nothing}
              ${Swatch(this.#makeColorMatrix([group]))}
            </tbody>`
        )}
      </table>
    </one-ux-scroll>`
  }

  protected get _selectedOption() {
    return this.#allColorOptions.find((option) => option.value === this.value)
  }

  protected _userSelectOption(option: optionData) {
    if (this.disabled) return

    this._activeOption = option
    this._applyUserValue(option.value)

    this._popoutContext.closePopout()

    this.dispatchEvent(new Event('input'))
    this.dispatchEvent(new Event('change'))

    this.requestUpdate()
  }

  #handleKeyDown = (event: KeyboardEvent) => {
    const handled = () => {
      event.preventDefault()
      event.stopPropagation()
    }

    const matrix = this.#makeColorMatrix(this._internalGroups)
    const activeOptionCalculator = new ActiveOptionCalculator(matrix, this._activeOption!)

    switch (event.code) {
      case keyCodes.LEFT:
        this._activeOption = activeOptionCalculator.tryGo('left')
        handled()
        break
      case keyCodes.RIGHT:
        this._activeOption = activeOptionCalculator.tryGo('right')
        handled()
        break
      case keyCodes.UP:
        this._activeOption = activeOptionCalculator.tryGo('up')
        handled()
        break
      case keyCodes.DOWN:
        this._activeOption = activeOptionCalculator.tryGo('down')
        handled()
        break
      case keyCodes.HOME:
        this._activeOption = activeOptionCalculator.tryGo('firstColumn')
        handled()
        break
      case keyCodes.END:
        this._activeOption = activeOptionCalculator.tryGo('lastColumn')
        handled()
        break
      case keyCodes.PAGEUP:
        this._activeOption = activeOptionCalculator.tryGo('previousPage')
        handled()
        break
      case keyCodes.PAGEDOWN:
        this._activeOption = activeOptionCalculator.tryGo('nextPage')
        handled()
        break
      case keyCodes.SPACE:
      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
        this._userSelectOption(this._activeOption!)
        handled()
        break
    }
  }

  #makeColorMatrix = (groups: groupData[]) => {
    const columns = NUMBER_OF_COLUMNS_IN_SWATCH
    return groups.reduce<colorMatrix>((matrix, { options }) => {
      const rows = []
      for (let i = 0; i < options.length; i += columns) {
        rows.push(options.slice(i, i + columns))
      }
      return [...matrix, ...rows]
    }, [])
  }

  #getDuplicateColors() {
    const testedColors = new Set<color>()
    const duplicates = new Set<color>()

    for (const { value } of this.#allColorOptions) {
      testedColors.has(value) ? duplicates.add(value) : testedColors.add(value)
    }

    return Array.from(duplicates)
  }

  get #allColorOptions() {
    return this._internalGroups.flatMap((group) => group.options)
  }

  #handleTooltip = (heading: string, $ref?: Element) => {
    if (!$ref) return

    requestAnimationFrame(() => {
      const $heading = $ref as HTMLElement

      const isTruncatedText = $heading.offsetWidth < $heading.scrollWidth
      if (isTruncatedText) {
        $heading.setAttribute('one-ux-tooltip', heading)
      } else {
        $heading.removeAttribute('one-ux-tooltip')
      }
      $heading.toggleAttribute('one-ux-tooltip-fixed', isTruncatedText)
    })
  }

  #langCode?: langCode
  #getLangCode = (): [langCode, boolean] => {
    const langCode = getLangCode(this)
    const isLanguageUpdated = langCode !== this.#langCode

    if (isLanguageUpdated) {
      this.#langCode = langCode
      this.lang = this.#langCode
    }

    return [langCode, isLanguageUpdated]
  }

  #refs: RootRefs = {
    $colorPicker: createRef(),
    $scroll: createRef()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-color-picker': OneUxColorPickerElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-color-picker': OneUxColorPickerElement
    }
  }
}

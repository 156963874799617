import { html, nothing, PropertyValues } from 'lit'
import { customElement, query, queryAssignedElements, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Placeholder } from '../../mixins/Placeholder.js'
import { Focusable } from '../../mixins/Focusable.js'
import { keyCodes } from '../../utils.js'
import { consume, provide } from '@lit/context'
import { labelContext, defaultLabelContext } from '../../contexts/LabelContext.js'
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js'
import { classMap } from 'lit/directives/class-map.js'
import { IPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { IDropdownContext, dropdownContext, updatePreviewOptions } from '../../contexts/DropdownContext.js'
import { Disabled } from '../../mixins/Disabled.js'

import type { OneUxPopoutElement } from '../../elements/one-ux-popout/OneUxPopoutElement.js'
import { FOCUSABLE_TARGETS_SELECTOR, TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js'
import { Optional } from '../../types.js'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Placeholder(Focusable(Styled(OneUxElement))))

@customElement('one-ux-dropdown')
export class OneUxDropdownElement extends BaseClass {
  /** @internal */
  @state()
  accessor _open = false

  /** @internal */
  @state()
  accessor _childComponentRequired = false

  /** @internal */
  @state()
  accessor _childComponentEmpty = false

  /** @internal */
  @state()
  accessor _preview = '' as unknown

  /** @internal */
  @state()
  accessor _previewOptions: Optional<updatePreviewOptions>

  /** @internal */
  @queryAssignedElements()
  accessor _dropdownContent!: Array<HTMLElement>

  /** @internal */
  @query('one-ux-popout')
  accessor _popoutElement!: OneUxPopoutElement

  /** @internal */
  @consume({ context: labelContext, subscribe: true })
  _labelContext = defaultLabelContext

  /** @internal */
  @provide({ context: dropdownContext })
  _dropdownContext: IDropdownContext = {
    updatePreview: (preview: unknown, options?: updatePreviewOptions) => {
      this._preview = preview
      this._previewOptions = options
    },
    placeholder: this.placeholder || '',
    disabled: this.disabled
  }

  /** @internal */
  @provide({ context: popoutContext })
  _popoutContext: IPopoutContext = {
    closePopout: (skipAutomaticFocus = false) => {
      if (this._open) {
        this.#toggleOpen(skipAutomaticFocus)
      }
    },
    openPopout: (skipAutomaticFocus = false) => {
      if (!this._open) {
        this.#toggleOpen(skipAutomaticFocus)
      }
    },
    isOpen: this._open
  }

  constructor() {
    super()

    this.width = 'max'
    this.height = 'max'

    this.addEventListener(
      'blur',
      (event: FocusEvent) => {
        const $newFocus = event.relatedTarget as HTMLElement
        if (this.contains($newFocus) || this.shadowRoot?.contains($newFocus)) {
          return
        }
        this.#performClose()
      },
      { capture: true }
    )
    this.addEventListener('keydown', this.#handleKeydown)

    this.addEventListener(InternalElementStateChangedEvent.eventName, (e: Event) => {
      const event = e as InternalElementStateChangedEvent

      if (event.target === this) {
        return
      }
      const { property, value } = event.detail
      switch (property) {
        case 'disabled':
          event.stopImmediatePropagation()
          break
        case 'required':
          this._childComponentRequired = value as boolean
          break
        case 'empty':
          this._childComponentEmpty = value as boolean
          break
      }
    })
  }

  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('placeholder') || changed.has('disabled')) {
      this._dropdownContext = {
        ...this._dropdownContext,
        placeholder: this.placeholder || '',
        disabled: this.disabled
      }
    }

    if (changed.has('_open')) {
      this._popoutContext = {
        ...this._popoutContext,
        isOpen: this._open
      }
    }
  }

  protected render() {
    return html`<div class="one-ux-element--root" @click=${this.#toggleOpen}>
      <div
        class="field-inner"
        role="${this._previewOptions?.interactive ? 'group' : 'combobox'}"
        aria-label=${ifDefined(this._labelContext.label || undefined)}
        aria-disabled=${this.disabled}
        aria-expanded=${ifDefined(this._previewOptions?.interactive ? undefined : this._open)}
        aria-required=${ifDefined(this._previewOptions?.interactive ? undefined : !!this._childComponentRequired)}
        tabindex=${ifDefined(this._previewOptions?.interactive ? undefined : this.disabled || this._open ? -1 : 0)}
      >
        <div
          class=${classMap({
            'field-inner-content': true,
            empty: this._childComponentEmpty
          })}
        >
          ${this._previewOptions?.interactive
            ? // Preview slot is not allowed for interactive previews as that can break component functionality
              this._preview
            : html` <slot name="preview"> ${this._childComponentEmpty ? this.placeholder : this._preview} </slot> `}
        </div>
        <one-ux-icon
          class="field-icon"
          set="${this._previewOptions?.icon?.set || 'default'}"
          icon="${this._previewOptions?.icon?.icon || 'toggle-down'}"
          aria-hidden="true"
          size="200"
        ></one-ux-icon>
      </div>

      ${!this._open
        ? nothing
        : html`
            <one-ux-popout
              @click=${(e: Event) => e.stopPropagation()}
              reference="parent"
              indent="none"
              indent-top="normal"
              indent-bottom="normal"
              offset-reference="6"
              .preventOverlap=${this._previewOptions?.interactive}
            >
              <slot></slot>
            </one-ux-popout>
          `}
    </div>`
  }

  #handleKeydown = (event: KeyboardEvent) => {
    if (this.disabled) {
      return
    }
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    switch (event.code) {
      case keyCodes.SPACE:
      case keyCodes.UP:
      case keyCodes.DOWN:
      case keyCodes.RETURN:
        if (!this._open) {
          this.#toggleOpen()
          return handled()
        }
        if (this._open && this._previewOptions?.interactive) {
          this.#setFocusToPopout()
          return handled()
        }
        return

      case keyCodes.ESCAPE:
        if (this._open) {
          this.#toggleOpen()
          handled()
        }
        return
    }
  }

  open() {
    if (this._open) {
      return
    }
    this.#toggleOpen()
  }

  close() {
    if (!this._open) {
      return
    }
    this.#toggleOpen()
  }

  #toggleOpen = (skipAutomaticFocus = false) => {
    if (this.disabled) {
      return
    }
    const shouldClose = this._open
    if (shouldClose) {
      if (!skipAutomaticFocus) {
        // Fix for bug in chromium where mouse leave events are not triggered if you remove a DOM element below the mouse and the mouse afterwards is outside the ShadowDOM
        const $fieldInner = this.shadowRoot!.querySelector<HTMLElement>('.field-inner')!
        this._previewOptions?.interactive
          ? ($fieldInner.querySelector<HTMLElement>(TABBABLE_TARGETS_SELECTOR) ||
              $fieldInner.querySelector<HTMLElement>(FOCUSABLE_TARGETS_SELECTOR))!.focus()
          : $fieldInner.focus()
      }
      this._popoutElement.hidden = true
      requestAnimationFrame(() => {
        this.#performClose()
      })
    } else {
      this.dispatchEvent(new Event('open', { bubbles: false, composed: false }))
      requestAnimationFrame(async () => {
        this._open = true
        await this.updateComplete
        requestAnimationFrame(async () => {
          if (this._popoutElement && this._popoutElement.parentElement) {
            const previewWidth = this._popoutElement.parentElement.getBoundingClientRect().width
            this._popoutElement.style.minWidth = previewWidth + 'px'
            this._popoutElement.style.setProperty(
              '--one-ux-element-internal--popout-constraint-max-width',
              `max(${previewWidth}px, 49vw)`
            )
          }

          if (skipAutomaticFocus) return
          this.#setFocusToPopout()
        })
      })
    }
  }

  async #setFocusToPopout() {
    const $child = this.#getFirstFocusableInPopout()
    if ($child) {
      if ('updateComplete' in $child) {
        await $child.updateComplete
      }
      $child.focus()
    }
  }

  #performClose() {
    this._open = false
    this._popoutContext = { ...this._popoutContext }
    requestAnimationFrame(() => {
      this.dispatchEvent(new Event('close', { bubbles: false, composed: false }))
    })
  }

  #getFirstFocusableInPopout() {
    for (const $element of this._dropdownContent) {
      if ($element.matches(TABBABLE_TARGETS_SELECTOR)) {
        return $element
      }
      const $focusableChild = $element.querySelector<HTMLElement | SVGElement>(TABBABLE_TARGETS_SELECTOR)
      if ($focusableChild) {
        return $focusableChild
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-dropdown': OneUxDropdownElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-dropdown': OneUxDropdownElement
    }
  }
}

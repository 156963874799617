import { html, nothing, TemplateResult } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { GroupData } from '../types.js'
import { MenuItem, MenuItemOptions } from './MenuItem.js'
import { Icon } from './Icon.js'
import { findIndexBasedOnType } from '../utils.js'
import { keyCodes } from '../../../utils.js'
import { OneUxMenuElement } from '../OneUxMenuElement.js'
import { KeyboardNavigationHandler } from '../KeyboardNavigationHandler.js'

export function Group(this: OneUxMenuElement, { item, itemTypeIndex }: MenuItemOptions<GroupData>): TemplateResult {
  const { children, expanded, text } = item
  const isExpanded = !!children?.length && expanded && !this.disabled

  const handleKeyDown = async (event: KeyboardEvent & { target: HTMLElement }) => {
    const navigationHandler = new KeyboardNavigationHandler(this, event)

    switch (event.code) {
      case keyCodes.RIGHT:
        return navigationHandler.openAndTryGoToGroup(item)
      case keyCodes.RETURN:
      case keyCodes.SPACE:
        return navigationHandler.toggleGroup(item)
      case keyCodes.UP:
        return navigationHandler.tryStep('up')
      case keyCodes.DOWN:
        return navigationHandler.tryStep('down')
      case keyCodes.LEFT:
        return navigationHandler.tryCloseGroup(item, 'preventAlways')
      case keyCodes.ESCAPE:
        return navigationHandler.tryCloseGroup(item, 'preventOnClose')
    }
  }

  const handleMouseMove = (event: MouseEvent & { target: HTMLElement }) => {
    if (this._hasFocus) {
      event.target.parentElement?.focus()
    }

    this._collapseItems()

    let group: GroupData | undefined = item
    while (group) {
      group.expanded = true
      group = group.parent
    }

    this.requestUpdate()
  }

  return html`
    <div
      role="menuitem"
      aria-haspopup="menu"
      aria-expanded=${isExpanded}
      aria-disabled=${this.disabled}
      pdr-test-hook=${`one-ux-menu-group-${itemTypeIndex}`}
      tabindex="-1"
      @keydown=${handleKeyDown}
      @mousedown=${(event: MouseEvent) => {
        this.disabled && event.preventDefault()
      }}
    >
      <div class="menu-item-content" @mousemove=${handleMouseMove}>
        <div id=${`group-content-${item.instanceId}`} class="group-content">${Icon(item.icon)} ${text}</div>
        <one-ux-icon aria-hidden="true" icon="toggle-right"></one-ux-icon>
      </div>
      ${isExpanded
        ? html`
            <one-ux-popout direction="horizontal" .offsetAlignment=${-8} indent="none">
              <one-ux-scroll implicit style="max-height: 35vh">
                <div
                  role="menu"
                  aria-labelledby=${`group-content-${item.instanceId}`}
                  style=${styleMap({
                    padding: 'var(--one-ux-spacing--normal)'
                  })}
                >
                  ${(children || []).map((child) =>
                    MenuItem.call(this, {
                      item: child,
                      itemTypeIndex: findIndexBasedOnType(children, child)
                    })
                  )}
                </div>
              </one-ux-scroll>
            </one-ux-popout>
          `
        : nothing}
    </div>
  `
}

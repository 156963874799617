import { html, PropertyValues } from 'lit'
import { customElement } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Errors } from '../../mixins/Errors.js'
import { Empty } from '../../mixins/Empty.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js'
import { Busy } from '../../mixins/Busy.js'
import { Label } from '../../mixins/Label.js'
import { log } from '../../utils/log.js'
import { provide } from '@lit/context'
import { ILabelContext, labelContext } from '../../contexts/LabelContext.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Busy(Label(Focusable(Empty(Errors(Styled(OneUxElement))))))

@customElement('one-ux-interactive-content')
export class OneUxInteractiveContentElement extends BaseClass {
  @provide({ context: labelContext })
  private _labelContext = {
    label: this.label
  } as ILabelContext

  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('label') && this._labelContext.label !== this.label) {
      this._labelContext = {
        label: this.label
      }
    }
  }

  render() {
    if (!this.label) {
      log.deprecation(
        '<one-ux-interactive-content> Missing attribute label, this element will stop working at some point in the near future if not provided.'
      )
    }

    return html`<div
      class=${classMap({
        'one-ux-element--root': true,
        errors: this.errors.length,
        empty: this.empty,
        busy: this.busy,
        done: this.done
      })}
    >
      <slot></slot>
      <div class="indicator"></div>
      ${ErrorsPopout({
        reference: 'parent',
        errors: this.errors,
        hidden: this.hideErrors
      })}
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-interactive-content': OneUxInteractiveContentElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-interactive-content': OneUxInteractiveContentElement
    }
  }
}

import { OneUxMenuElement } from './OneUxMenuElement.js'
import { GroupData, OptionData } from './types.js'

export class KeyboardNavigationHandler {
  constructor(
    private host: OneUxMenuElement,
    private event: KeyboardEvent & { target: HTMLElement }
  ) {}

  #element: HTMLElement = this.event.target

  public async openAndTryGoToGroup(item: GroupData) {
    this.#handled()

    item.expanded = true
    this.host.requestUpdate()
    await this.host.updateComplete

    // Awaits one frame for OneUxPopoutElement to be displayed and not just rendered in markup
    requestAnimationFrame(() => {
      const $firstItem = this.#element.querySelector<HTMLElement>('[role="menu"] [role="menuitem"]')
      $firstItem?.focus()
    })
  }

  public toggleGroup(item: GroupData) {
    this.#handled()
    item.expanded = !item.expanded
    this.host.requestUpdate()
  }

  public tryCloseGroup(item: GroupData | OptionData, prevent: 'preventAlways' | 'preventOnClose') {
    prevent === 'preventAlways' && this.#handled()

    if (item.parent) {
      const $parent = this.#element.parentElement?.closest<HTMLElement>('[role="menuitem"]')
      $parent!.focus()
      item.parent.expanded = false
      prevent === 'preventOnClose' && this.#handled()
      this.host.requestUpdate()
    }
  }

  public tryStep(direction: 'up' | 'down') {
    const $items = Array.from<HTMLElement>(this.#element.parentElement!.querySelectorAll(':scope > [role="menuitem"]'))
    const currentIndex = $items.indexOf(this.#element)
    const delta = direction === 'up' ? -1 : 1
    const $newItem = $items[currentIndex + delta]
    $newItem?.focus()
    this.#handled()
  }

  #handled() {
    this.event.preventDefault()
    this.event.stopPropagation()
  }
}

import { createContext } from '@lit/context'

export type avatarState = 'none' | 'removed' | 'anonymized' | 'impersonated' | 'unknown'

export interface IAvatarContext {
  getAvatarUrl(userId: string, preferredSize: number): Promise<string>
  getFullName(userId: string): Promise<string>
  getState(userId: string): Promise<avatarState>
}

const contextKey = Symbol('one-ux-avatar-context')

export const defaultAvatarContext = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAvatarUrl(userId: string, preferredSize: number): Promise<string> {
    return Promise.reject()
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFullName(userId: string): Promise<string> {
    return Promise.reject()
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getState(userId: string): Promise<avatarState> {
    return Promise.reject()
  }
} satisfies IAvatarContext

export const avatarContext = createContext<IAvatarContext>(contextKey)

import { css, unsafeCSS, LitElement, ReactiveController, nothing } from 'lit'
import { property } from 'lit/decorators.js'
import { style as typography } from './generated/typography.js'

export interface OneUxElementTheme<T> {
  light: T
  dark: T
}

export const sizeValues = [
  'auto',
  'min',
  'max',
  'screen',
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
  '12/12'
] as const
export type size = (typeof sizeValues)[number]

const baseStyle = getOneUxElementStyle()
export class OneUxElement extends LitElement {
  static styles = [typography, baseStyle]

  /**
   * The width of the component in relation to its parent.
   */
  @property({ type: String, reflect: true })
  public accessor width: size = 'min'

  /**
   * The height of the component in relation to its parent.
   */
  @property({ type: String, reflect: true })
  public accessor height: size = 'min'

  constructor() {
    super()
    new OneUxElementController(this)
  }

  protected render() {
    if (this.#runRenderGuards()) {
      return nothing
    }
    return this.guardedRender()
  }

  protected guardedRender(): unknown {
    return
  }

  protected _renderGuards = [] as (() => boolean)[]
  #runRenderGuards() {
    let failed = false
    for (const guard of this._renderGuards) {
      if (guard()) {
        failed = true
      }
    }
    return failed
  }
}

class OneUxElementController implements ReactiveController {
  constructor(private host: OneUxElement) {
    this.host.addController(this)
  }

  hostConnected(): void {
    this.host.toggleAttribute('one-ux-element', true)
  }
}

function getOneUxElementStyle() {
  return css`
    :host {
      --one-ux-element--width: auto;
      --one-ux-element--height: auto;

      display: inline-flex !important;
      box-sizing: border-box;
      width: var(--one-ux-element--width);
      height: var(--one-ux-element--height);
      margin: 0 var(--one-ux-element--separate);
    }

    .one-ux-element--root {
      flex: 1 1 auto;
      min-width: 0;
    }

    :host([hidden]) {
      display: none !important;
    }

    :host(:is([width='auto'], [width='max'])) {
      display: flex !important;
      margin: var(--one-ux-element--separate) 0;
    }

    :host([width='max']) {
      width: 100%;
    }

    :host([width='screen']) {
      width: 100vw;
    }

    ${mapDimensionFractions(
      (x) => `
        :host([width='${x}/12']) {
          width: calc(100% * ${x} / 12);
        }
      `
    )}

    :host([height='max']) {
      height: 100%;
    }

    :host([height='screen']) {
      height: 100vh;
    }

    ${mapDimensionFractions(
      (x) => `
        :host([height='${x}/12']) {
          height: calc(100% * ${x} / 12);
        }
      `
    )}

    .one-ux-accessibility--screen-reader {
      position: fixed;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
  `
}

export function mapDimensionFractions(callback: (x: number) => string) {
  return unsafeCSS([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(callback).join('\n'))
}

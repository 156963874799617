import { css } from 'lit'

export const style = css`
  :host {
    width: 196px;
  }

  .one-ux-element--root {
    --one-ux-slider-element--indent: 11px;

    --one-ux-slider-element--gap: 4px;

    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-slider-element--handle-border-color--hover: var(--one-ux-palette--brand-neutral-400);
    --one-ux-slider-element--handle-border-color--active: var(--one-ux-palette--brand-neutral-600);
    --one-ux-slider-element--handle-color: var(--one-ux-palette--gray-100);
    --one-ux-slider-element--handle-color--hover: var(--one-ux-palette--gray-100);
    --one-ux-slider-element--handle-color--active: var(--one-ux-palette--gray-100);
    --one-ux-slider-element--handle-size: 16px;
    --one-ux-slider-element--handle-radius: 8px;

    --one-ux-slider-element--progress-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-slider-element--progress-color--active: var(--one-ux-slider-element--progress-color);

    --one-ux-slider-element--track-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.3);
    --one-ux-slider-element--track-height: 4px;
    --one-ux-slider-element--track-radius: 2px;

    --one-ux-slider-element--ticks-border-radius: 1px;
    --one-ux-slider-element--ticks-height: 3px;
    --one-ux-slider-element--ticks-width: 2px;
    --one-ux-slider-element--ticks-color: var(--one-ux-palette--brand-neutral-600);
  }

  .main {
    padding: 8px var(--one-ux-slider-element--indent);
    font: var(--one-ux-font--label-300);
  }

  .foreground-start input[type='range']:first-child {
    z-index: 2;
  }

  .summary {
    color: var(--one-ux-palette--brand-neutral-500);
  }

  .summary span {
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .fields {
    --index-diff: calc(var(--max-index) - var(--min-index));
    display: grid;
    align-items: center;
    grid-template:
      var(--one-ux-slider-element--handle-size) var(--one-ux-slider-element--ticks-size)
      / 1fr 1fr;
    overflow: hidden;
    position: relative;
    height: var(--one-ux-slider-element--handle-size);
    background: none;
    padding-top: var(--one-ux-slider-element--gap);
    margin: 0 calc(var(--one-ux-slider-element--handle-radius) * -1);
  }

  .fields::after,
  .fields::before {
    grid-column: 1 / span 2;
    grid-row: 1;
    content: '';
    height: var(--one-ux-slider-element--track-height);
    border-radius: var(--one-ux-slider-element--track-radius);
  }

  .fields::before {
    background: var(--one-ux-slider-element--track-color);
    margin: 0 var(--one-ux-slider-element--handle-radius);
  }

  .fields::after {
    --progress: (var(--progress-end-index) - var(--progress-start-index)) / var(--index-diff);
    background: var(--one-ux-slider-element--progress-color);
    margin-left: calc(
      var(--one-ux-slider-element--handle-radius) + (var(--progress-start-index) - var(--min-index)) / var(--index-diff) *
        calc(100% - var(--one-ux-slider-element--handle-radius))
    );
    width: calc(var(--progress) * 100% - var(--progress) * var(--one-ux-slider-element--handle-size));
  }

  .fields.active::after {
    background: var(--one-ux-slider-element--progress-color--active);
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    grid-column: 1 / span 2;
    grid-row: 1;
    z-index: 1;
    background: none;
    margin: 0 1px;
    width: calc(100% - 2px);
    height: 100%;
  }

  :host([range]) input[type='range'] {
    pointer-events: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']:disabled {
    pointer-events: none;
  }

  /*
    Code must be duplicated for thumbs,
    since it is not possible to comma separate the selector (blame Chrome).
    Source: https://css-tricks.com/sliding-nightmare-understanding-range-input/
  */

  /* - Firefox - */
  input[type='range']::-moz-range-track {
    appearance: none;
    background: none;
    height: 100%;
    width: 100%;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    pointer-events: auto;
    box-sizing: border-box;
    margin-top: 0;
    background: var(--one-ux-slider-element--handle-color);
    border: 2px solid var(--one-ux-slider-element--handle-border-color);
    border-radius: var(--one-ux-slider-element--handle-radius);
    width: var(--one-ux-slider-element--handle-size);
    height: var(--one-ux-slider-element--handle-size);

    transition-property: background-color, border-color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
    cursor: pointer;
  }

  input[type='range']:hover::-moz-range-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-slider-element--handle-color--hover);
    --one-ux-slider-element--handle-border-color: var(--one-ux-slider-element--handle-border-color--hover);
  }

  input[type='range']:active::-moz-range-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-slider-element--handle-color--active);
    --one-ux-slider-element--handle-border-color: var(--one-ux-slider-element--handle-border-color--active);
  }

  input[type='range']:disabled::-moz-range-thumb {
    pointer-events: none;
  }

  /* - Webkit - */
  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    height: 100%;
    width: 100%;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    pointer-events: auto;
    box-sizing: border-box;
    margin: 0;
    background: var(--one-ux-slider-element--handle-color);
    border: 2px solid var(--one-ux-slider-element--handle-border-color);
    border-radius: var(--one-ux-slider-element--handle-radius);
    width: var(--one-ux-slider-element--handle-size);
    height: var(--one-ux-slider-element--handle-size);

    transition-property: background-color, border-color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
    cursor: pointer;
  }

  input[type='range']:hover::-webkit-slider-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-slider-element--handle-color--hover);
    --one-ux-slider-element--handle-border-color: var(--one-ux-slider-element--handle-border-color--hover);
  }

  input[type='range']:active::-webkit-slider-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-slider-element--handle-color--active);
    --one-ux-slider-element--handle-border-color: var(--one-ux-slider-element--handle-border-color--active);
  }

  input[type='range']:disabled::-webkit-slider-thumb {
    pointer-events: none;
  }
  /* end of duplicaed code */

  .axis {
    display: flex;
    justify-content: space-between;
    height: var(--one-ux-slider-element--gap);
    margin: 0;
  }

  .axis > i {
    height: var(--one-ux-slider-element--ticks-height);
    width: var(--one-ux-slider-element--ticks-width);
    background-color: var(--one-ux-slider-element--track-color);
    border-radius: var(--one-ux-slider-element--ticks-border-radius);
  }

  .ruler {
    display: flex;
    justify-content: space-between;
    color: var(--one-ux-palette--brand-neutral-500);
  }

  /* Focus */

  :host([state-keyboard-focus]) .main {
    background: var(--one-ux-palette--keyboard-focus-100) !important;
  }

  :host([state-keyboard-focus]) input:focus::-webkit-slider-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-palette--keyboard-focus-200) !important;
    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--keyboard-focus-400) !important;
  }

  :host([state-keyboard-focus]) input:focus::-moz-range-thumb {
    --one-ux-slider-element--handle-color: var(--one-ux-palette--keyboard-focus-200) !important;
    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--keyboard-focus-400) !important;
  }

  /* Purposes */

  :host([purpose='main']) .fields {
    --one-ux-slider-element--handle-border-color--active: var(--one-ux-palette--purpose-action-primary);
    --one-ux-slider-element--progress-color: var(--one-ux-palette--purpose-action-primary);
    --one-ux-slider-element--progress-color--active: var(--one-ux-palette--purpose-action-primary);
  }

  :host([purpose='caution']) .fields {
    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--red-300);
    --one-ux-slider-element--handle-border-color--hover: var(--one-ux-palette--red-400);
    --one-ux-slider-element--handle-border-color--active: var(--one-ux-palette--red-300);
    --one-ux-slider-element--handle-color--active: var(--one-ux-palette--red-100);
    --one-ux-slider-element--progress-color: var(--one-ux-palette--red-300);
    --one-ux-slider-element--progress-color--active: var(--one-ux-palette--red-400);
  }

  :host([purpose='notice']) .fields {
    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--azure-400);
    --one-ux-slider-element--handle-border-color--hover: var(--one-ux-palette--azure-500);
    --one-ux-slider-element--handle-border-color--active: var(--one-ux-palette--azure-400);
    --one-ux-slider-element--handle-color--active: var(--one-ux-palette--azure-200);
    --one-ux-slider-element--progress-color: var(--one-ux-palette--azure-400);
    --one-ux-slider-element--progress-color--active: var(--one-ux-palette--azure-500);
  }

  /* Disabled */

  :host([disabled]) .fields {
    --one-ux-slider-element--progress-color: var(--one-ux-palette--gray-400);
    --one-ux-slider-element--handle-color: var(--one-ux-palette--gray-150);
    --one-ux-slider-element--handle-border-color: var(--one-ux-palette--gray-400);
  }
`
